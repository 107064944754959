import React, { useState } from "react";
import { Link } from "react-router-dom";
import RxButton from "../components/RxButton";

function AboutFour() {
  const [isOpen, setIsOpen] = useState(false);

  const openVideo = () => {
    setIsOpen(true);
  };

  const closeVideo = () => {
    setIsOpen(false);
  };

  const handleKeyUp = (e) => {
    if (e.key === "Escape") {
      closeVideo();
    }
  };

  return (
    <div>
      {/* Tpm About Area Start  */}
      <div className="about-area tmp-section-gapBottom about-style-one">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img
                src="assets/images/about/light_bulb.jpg"
                alt="Corporate_service"
              />
            </div>
            <div className="col-lg-6">
              <div className="about-inner">
                <div className="section-head text-align-left section-head-one-side">
                  <div className="section-sub-title">
                    <span className="subtitle">Preserving Relationships</span>
                  </div>
                  <h2 className="title split-collab">
                    Mediators with Real World Commercial Experience
                  </h2>
                </div>

                {/* Main Description */}
                <p
                  className="description"
                  data-sal-delay={200}
                  data-sal="slide-up"
                  data-sal-duration={800}
                  style={{ lineHeight: "1.6" }}
                >
                  In high-pressured environments, disputes are common due to the
                  complexity of contracts with suppliers and vendors, project
                  delays or quality of goods and services not meeting
                  expectations. In addition, managing multiple stakeholders on
                  high profile projects can sometimes lead to unresolved
                  conflicts that can be very costly for organisations, both in
                  terms of time and money.
                </p>
                <p className="description" style={{ lineHeight: "1.6" }}>
                  According to our research, employees spend an average of 2.8
                  hours per week on conflict resolution, leading to significant
                  productivity loss. In addition, replacing employees due to
                  unresolved disputes may cost companies from 50%-200% of the
                  employee's annual salary.
                </p>
                <p className="description" style={{ lineHeight: "1.6" }}>
                  MNATION Advisory has a team of Mediators whom each have at
                  least 20 to 30 + years' experience across various sectors of
                  industry. Our unique methodology backed by AI-powered
                  behavioural science and an online end-to-end case management
                  platform, helps parties to resolve disputes quickly and
                  discreetly, so they can move forward and continue to build
                  trust and mutual prosperity with their valued partners.
                </p>

                {/* Contact Us */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Tpm About Area End  */}
    </div>
  );
}

export default AboutFour;
