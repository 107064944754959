import React, { useState } from "react";
import emailjs from "emailjs-com";
import Breadcrumb from "./breadcrumb/Breadcrumb";
import Footer from "../elements/Footer";
import Header from "../header/Header";


function BookingPage () {
  return ( <div className="">
  <Header/>
  <main>
     <Breadcrumb currentPage='Schedule a meeting' bgClass='breadcrumb-bg'  specialInnerClass='service-inner'/>
     <div style={{ padding: "20px" }}>
      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
        Book a Discovery Session
      </h1>
      <div style={{ border: "1px solid #ddd", borderRadius: "8px", overflow: "hidden" }}>
        <iframe
          src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0b7kKFTiseQ6Oazw5SHi8_Bamd1DjxtQdsk6Oys5x0lrapcP8o1dWyK-TJD5l7WC1NCT1KZICV?gv=true"
          style={{ border: 0, width: "100%", height: "600px" }}
          frameBorder="0"
          title="Google Calendar Booking"
          allowFullScreen
        ></iframe>
      </div>
    </div>
      {/* <WorkingProcessThree/> */}
      {/* <Team/>
      <TestimonialsBrand/> */}
      <Footer/>

  </main>
</div>)
}

export default BookingPage ;
