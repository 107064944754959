import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';

import RxButton from "../components/RxButton";

function BannerFour() {



  return (
    <div>
      {/* tmp banner area start */}
      <div className="startup-banner-swiper-wrapper">
      <div className="swiper swiper-startup-banner">
      <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, EffectFade, Scrollbar, A11y, Autoplay]}
                        className="swiper-startup-banner"
                        speed={500}
                        slidesPerView={1}
                        spaceBetween={0}
                        loop={true}
                        autoplay={{
                            delay: 5500,
                            disableOnInteraction: false,
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        pagination={{ clickable: true }}
                        breakpoints={{
                            1200: { slidesPerView: 1 },
                            900: { slidesPerView: 1 },
                            768: { slidesPerView: 1 },
                            580: { slidesPerView: 1 },
                            450: { slidesPerView: 1 },
                            0: { slidesPerView: 1 },
                        }}
                    >
                    <SwiperSlide>
      <div className="tmp-banner-area banner-style-five tmp-section-gap banner-bg-startup  bg_image">
                            <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                <div className="banner-startup-main-content-wrapper">
                                    <h1 className="title">
                                    MNATION ADVISORY
                                    </h1>
                                    <p className="disc">
                                    Mediation Services Specialising in Commercial Disputes Coming Soon in 2026
                                    </p>
                                    
                                  <div style={{marginTop: 15}}> <RxButton className="main-btn"/></div> 
                                  
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </SwiperSlide>
                        </Swiper>
    

      {/* tmp banner area end */}
    </div>
    </div>
    </div>
  );
}

export default BannerFour;
