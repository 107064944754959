import React, { useState } from "react";
import emailjs from "emailjs-com";
import Breadcrumb from "./breadcrumb/Breadcrumb";
import Footer from "../elements/Footer";
import Header from "../header/Header";
import RxButton from "../components/RxButton";
import axios from "axios";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function ContactPage() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    executeRecaptcha("formSubmit").then((token) => {
      const urlLocal =
        "http://127.0.0.1:5001/mnation-9bdcb/us-central1/mnationWeb";
      const url = "https://mnationweb-o6qepblaha-uc.a.run.app";
      axios
        .post(url + "/onAdvisoryContactSubmited", { ...formData, token })
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            alert("Message sent successfully!");
            setFormData({
              name: "",
              phone: "",
              email: "",
              subject: "",
              message: "",
            });
          },
          (err) => {
            console.log("FAILED...", err);
            alert("Failed to send message. Try again.");
          }
        );
    });
  };

  return (
    <div>
      <div className="index-Four">
        <Header />
        <main>
          <Breadcrumb currentPage="Contact us" bgClass="contact-bg" />
          {/* tmp contact area */}
          <div className="contact-area tmp-section-gap">
            <div className="container">
              <div className="row g-5">
                <div className="col-lg-5">
                  <div className="contact-inner">
                    <div className="section-head section-head-one-side text-align-left">
                      <span className="title">Contact Us</span>
                      {/* <p className="description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolor magnaaliqua.
                                </p> */}
                    </div>
                    <ul className="ft-link ft-link-style-three">
                    <li>
                        <div className="single-contact background-transparent">
                          <div className="icon">
                            <i
                              className="fa-light fa-calendar"
                              style={{ color: "black" }}
                            />
                          </div>
                          <div className="content">
                            <span>Book Discovery Session</span>
                            <a className="contact-here" href="/book">
                              Click Here
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="single-contact background-transparent">
                          <div className="icon">
                            <i
                              className="fa-light fa-phone"
                              style={{ color: "black" }}
                            />
                          </div>
                          <div className="content">
                            <span>Call Us</span>
                            <a className="contact-here" href="tel:+6585049997">
                            +65 8504 9997
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="single-contact background-transparent">
                          <div className="icon">
                            <i
                              className="fa-solid fa-envelope-open-text"
                              style={{ color: "black" }}
                            />
                          </div>
                          <div className="content">
                            <span>E-mail</span>
                            <a
                              className="contact-here"
                              href="mailto:joanne.smith@mnation.uk"
                            >
                              joanne.smith@mnation.uk
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* <li>
                                    <div className="single-contact background-transparent">
                                    <div className="icon">
                                        <i className="fa-regular fa-map-location-dot" />
                                    </div>
                                    <div className="content">
                                        <span>Address</span>
                                        <a className="contact-here" href="#">
                                        25/5 Double Street, Texas Houston USA
                                        </a>
                                    </div>
                                    </div>
                                </li> */}
                    </ul>
                    <div className="tmp-tag-list tag-list-one">
                      {/* <a className="tag-list" href="#">
                                    Linkedin
                                </a> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="contact-inner">
                    <div className="section-head section-head-one-side text-align-left">
                      <span className="title">Send a Message</span>
                      {/* <p className="description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolor magnaaliqua.
                                </p> */}
                    </div>
                    <div className="contact-form style-two">
                      <div id="form-messages" />
                      <form id="contact-form">
                        <div className="contact-form-wrapper row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input
                                className="input-field"
                                name="name"
                                placeholder="Name"
                                id="contact-name"
                                type="text"
                                value={formData.name}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input
                                className="input-field"
                                name="phone"
                                placeholder="Phone"
                                type="text"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input
                                className="input-field"
                                name="email"
                                placeholder="Email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input
                                className="input-field"
                                name="subject"
                                placeholder="Subject"
                                type="text"
                                value={formData.subject}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <textarea
                                className="input-field"
                                name="message"
                                placeholder="How can we help"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                id="contact-message"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <RxButton
                              Text="Submit Now"
                              onClick={() => handleSubmit()}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* tmp contact area */}
          {/* <div className="tmp-map-area tmp-sectiongapBottom">
                        <div className="map-area-flotimg-container">
                        <div style={{alignSelf:"center", justifyContent:"center", display:"flex", marginBottom:50}}>
                            <a href="/">
                            <img src="assets/images/logo/logo_horizontal_trans.png" alt="logo" style={{backgroundColor:"black", height:150}}/>
                            </a>
                        </div>
                        
                        </div>
                    </div> */}
          <Footer />
        </main>
      </div>
    </div>
  );
}

export default ContactPage;
