import React, { useEffect } from "react";
import WOW from "wowjs";
const clientLevels = [
  {
    index: 1,
    title: "Resolve. Preserve. Prosper.",
    description:
      `We believe that resolving disputes quickly and amicably is the beginning of our customer journey. </br>
Our mission is for clients to strengthen their relationships beyond resolution. That requires us taking a wholistic view of mediation services.</br> 
We combine the best of AI-driven insights, commercial acumen and behavioural science to help our clients resolve the conflict quickly, preserve their relationships and prosper, together.
`,
  },
  {
    index: 2,
    title: "Online for Easy And Discreet Access",
    description:
      `Our highly qualified team, combined with an online, end-to-end case management platform helps businesses to come together to resolve disputes quickly and discreetly, so they can move forward and continue building trust and mutual prosperity with their valued partners. `},
  {
    index: 3,
    title: "Technology",
    description:
      `Our online platform makes the case management process discreet, easy and intuitive to use.</br>  
By using AI-powered technology, we can analyze data, identify patterns and provide insights that may suggest win/win scenarios, helping parties reach agreements faster.
`
  },
  {
    index: 4,
    title: "Affordable",
    description:
      `Based on our research, AI-powered mediation services can result in up to 50 percent savings to clients than traditional methods. </br>
We aim to make our services the most accessible and affordable mediation service available on the market. </br>
For most cases, we can offer mediation services remotely. This helps to reduce ancillary costs associated with time, travel costs and lost productivity due to unresolved disputes.
`},
];

function renderProcess({ title, index, description }) {
  return (
    <div
      className="col-lg-3 col-md-6 col-sm-6 col-12 wow fadeInLeft"
      data-wow-duration=".8s"
      data-wow-delay={`${index * 0.1}s`} // Delay based on index
    >
      <div className="single-process">
        <div className="services-inner">
          <div className="services-content services-content-style-1 text-center">
            <h5 className="title" style={{ textAlign: "left" }}>
              {title}
            </h5>
            {description.split("</br>").map((desc)=>{
                        if (String(desc).includes("<a")) {
                          return <p className="description"  dangerouslySetInnerHTML={{
                            __html:desc
                          }}/>
                        }
                        return <p className="description" style={{textAlign:"justify",margin: 5}}>{desc}</p>
                      })}
           
          </div>
        </div>
      </div>
    </div>
  );
}
function WorkingProcess() {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false, // This disables continuous scanning for new elements
    });
    wow.init();
  }, []);
  return (
    <div>
      <div className="tpm-services-process-area tmp-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
                <div className="section-sub-title center-title"></div>
                <h2 className="title split-collab">
                MNA is a legal-tech firm that provides end-to-end mediation services specialising in commercial disputes. 
                </h2>
              </div>
            
          </div>
          <div className="row g-5">{clientLevels.map(renderProcess)}</div>
        </div>
      </div>
    </div>
  );
}

export default WorkingProcess;
