import React, { useState, useRef, useEffect } from "react";
import Nav from "./Nav";
import { Link } from "react-router-dom";

function Header() {
  const [isSticky, setIsSticky] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // header side collups area start here

  const [isOpen, setIsOpen] = useState(false);
  const pickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // side menu active
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuVisible(!isMenuVisible);
    setIsOverlayVisible(!isOverlayVisible);
  };

  const handleCloseMenu = () => {
    setIsMenuVisible(false);
    setIsOverlayVisible(false);
  };

  const handleOverlayClick = () => {
    setIsMenuVisible(false);
    setIsOverlayVisible(false);
  };

  // mobile menu
  const [openMenu, setOpenMenu] = useState(null);

  const toggleMenu = (menuhome) => {
    setOpenMenu(openMenu === menuhome ? null : menuhome);
  };

  return (
    <div>
      <header
        className={`tmp-header-area-start header-two header-four header-five header--sticky ${
          isSticky ? "sticky" : ""
        }`}
      >
        {/* header-top start */}
        <div className="header-top-one">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="header-top-inner">
                  <div className="left-information-area">
                    <p className="left-top">
                      <Link to={"tel:+6585049997"}>
                        <i className="fa-regular fa-phone" /> +65 8504 9997
                      </Link>
                    </p>
                  </div>
                  {/* <div className="right-header-top">
                        <div className="social-area-transparent">
                        <span>Follow on</span>
                       
                        
                        <Link to={'#'}>
                            <i className="fa-brands fa-linkedin-in" />
                        </Link>
                        </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* header-top end */}
        {/* header mid area start */}
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="header-mida-area style-two ">
                <div className="logo-area-start">
                  <Link className="logo" to="/">
                    <img
                      alt="Corporate_business_logo"
                      src="assets/images/logo/logo_horizontal_trans.png"
                      width={200}
                    />
                  </Link>
                </div>
                {/* <Nav /> */}
                {/* <Link to={'#'} class="tmp-btn btn-primary">Get Consulting</Link> */}
                <div className="actions-area">
                  {/* <div class="menu-button" id="search">
                        <i class="fa-light fa-grid-2"></i>
                        </div> */}
                  <div
                    className="tmp-side-collups-area"
                    id="side-collups"
                    onClick={handleMenuToggle}
                  >
                    <svg
                      width={20}
                      height={16}
                      viewBox="0 0 20 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect y={14} width={20} height={2} fill="#fff" />
                      <rect y={7} width={20} height={2} fill="#fff" />
                      <rect width={20} height={2} fill="#fff" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* header mid area end */}
      </header>

      <div id="side-hide" className={isMenuVisible ? "show" : ""}>
        <div className="top-area">
          <Link href="/" className="logo-area">
            <img
              src="assets/images/logo/log_vertical_trans.png"
              alt="logo"
              width={75}
            />
          </Link>

          <div className="close-icon-area">
            <div id="close-slide__main" onClick={handleOverlayClick}>
              <i className="fa-solid fa-x" />
            </div>
          </div>
        </div>

        {/* mobile menu area start */}
        {/* <div className="mobile-menu-main">
            <nav className="nav-main mainmenu-nav mt--30">
                <Nav/>
            </nav>
            </div> */}
        {/* mobile menu area end */}
      </div>

      <div
        id="overlay_every-where"
        className={isOverlayVisible ? "bgshow" : ""}
        onClick={handleOverlayClick}
      ></div>
    </div>
  );
}

export default Header;
