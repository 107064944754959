import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <>
        <footer className="footer-area footer-style-one-wrapper bg-color-footer bg_images tmp-section-gap">
          <div className="container">
          <div className="row">
                    <div className="col-lg-12">
                    <div className="subscribe-area subscribe-style-1">
                        <div className="subscribe-inner">
                        <div className="title" style={{textAlign:"center"}}><Link to={"/contact"} >Interested to know more before we launch? Register your interest today!</Link></div>
      
                        </div>
                    </div>
                    </div>
                </div>
            <div className="footer-main footer-style-one">
              <div className="row g-5">
                <div className="col-lg-4 col-md-6">
                  <div className="single-footer-wrapper border-right mr--20">
                    <div className="logo">
                      <Link to="/">
                        <img
                          src="assets/images/logo/log_vertical_trans.png"
                          width={100}
                          alt="logo"
                        />
                      </Link>
                    </div>
                    <p className="description">
                    MNATION Advisory is a legal tech firm that provides end-to-end mediation services specialising in commercial disputes. 
                    </p>
                   
                    {/* <ul className="social-icons solid-social-icons rounded-social-icons">
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-linkedin-in" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-instagram" />
                        </Link>
                      </li>
                    </ul> */}
                  </div>
                </div>
                <div className="col-lg-4 col-md-3">
                  <div className="single-footer-wrapper pl-50 pl_md--0 pl_sm--0">
                    <h5 className="ft-title">Quick Link</h5>
                    <ul className="ft-link">
                      {/* <li>
                        <Link to={"/about"}>About Company</Link>
                      </li> */}
                  
                      {/* <li>
                        <Link to={"/service"}>Our Services</Link>
                      </li> */}
                     
                      <li>
                        <Link to={"/contact"}>Contact Us</Link>
                      </li>
                      
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-3">
                  <div className="single-footer-wrapper">
                    <h5 className="ft-title">Contact Us</h5>
                    <ul className="ft-link">
                      {/* <li className="ft-location">
                        5919 Trussville Crossings Pkwy, new Dusting town,
                        Austria
                      </li> */}
                      <li>
                        <div className="single-contact">
                          <div className="icon">
                            <i className="fa-solid fa-envelope-open-text" />
                          </div>
                          <div className="content">
                            <span>E-mail:</span>
                            <Link to="mailto:joanne.smith@mnation.uk">
                            joanne.smith@mnation.uk
                            </Link>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="single-contact">
                          <div className="icon">
                            <i className="fa-light fa-phone" />
                          </div>
                          <div className="content">
                            <span>Phone:</span>
                            <Link to="tel:+6585049997">+65 8504 9997</Link>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="copyright-area-one">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="main-wrapper">
                  <p>
                    © Copyright 2024. All Rights Reserved by{" "}
                    <Link to="#">MNA</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Footer;
